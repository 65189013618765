import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";
import axios from 'axios';

export default function UsersNew() {
    const [editRowIndex, setEditRowIndex] = useState(null);
    const navigate = useNavigate();


    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://api.kpsskod.com/api/user?skip=0&take=10', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        _limit: 5,
                    },
                });

                const userData = response.data.map(user => ({
                    isim: user.name,
                    soyisim: user.lastname || '',
                    telefon: user.gsm || '',
                    tip: user.type || '',
                    kuponKodu: (user.coupon) ? user.coupon : 'Yok' || '',
                    aktif: user.isActive,
                    olusturmaZamani: user.createdTime || '',
                    id: user.id
                }));

                setGridData(userData);

            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    function handleEdit(index) {
        setEditRowIndex(index);
    }

    function handleDelete(index) {
        const newData = [...gridData];
        newData.splice(index, 1);
        setGridData(newData);
    }

    function handleSave() {
        setEditRowIndex(null);
    }

    function handleCancel() {
        setEditRowIndex(null);
    }

    function handleChange(event, key, index) {
        const newData = [...gridData];
        if (key === 'aktif') {
            newData[index][key] = event.target.checked;
        } else {
            newData[index][key] = event.target.value;
        }
        setGridData(newData);
    }

    const handleNavigate = (userId) => {
        navigate(`/users-detail-new/${userId}`);
    };
    function handleAddRow() {
        const newRow = {
            dersAdi: '',
            ucret: '',
            siralama: '',
            aktif: false,
            olusturmaZamani: new Date().toLocaleString()
        };
        setGridData([...gridData, newRow]);
        setEditRowIndex(gridData.length);
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="#">Kullanıcılar</Link></li>
                        </ol>
                        <h2 className="main-title mb-5">Kullanıcılar</h2>
                        <Button onClick={handleAddRow} className="mb-3">Yeni Veri Ekle +</Button>

                        <Grid
                            data={gridData.map((row, rowIndex) => {

                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('input', {
                                            value: row.isim,
                                            onChange: (e) => handleChange(e, 'isim', rowIndex),
                                            style: { width: '100%' },
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('input', {
                                            value: row.soyisim,
                                            onChange: (e) => handleChange(e, 'soyisim', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }

                                        }),
                                        h('input', {
                                            value: row.telefon,
                                            onChange: (e) => handleChange(e, 'telefon', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('select', {
                                            value: row.tip,
                                            onChange: (e) => handleChange(e, 'tip', rowIndex),
                                            style: { width: '100%' }
                                        },
                                            h('option', { value: 'Admin' }, 'Admin'),
                                            h('option', { value: 'user' }, 'user'),
                                            h('option', { value: 'influencer' }, 'influencer')
                                        ),
                                        h('input', {
                                            value: row.kuponKodu,
                                            onChange: (e) => handleChange(e, 'kuponKodu', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            type: 'checkbox',
                                            checked: row.aktif,
                                            onChange: (e) => handleChange(e, 'aktif', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.olusturmaZamani,
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'orange', border: 'none', background: 'none' }
                                            }, '❌')
                                        )
                                    ];
                                } else {
                                    return [
                                        row.isim,
                                        row.soyisim,
                                        row.telefon,
                                        row.tip,
                                        row.kuponKodu,
                                        row.aktif ? '✅' : '❌',
                                        row.olusturmaZamani,
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: () => handleEdit(rowIndex),
                                                style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                            }, '✏️'),
                                            h('button', {
                                                onClick: () => handleDelete(rowIndex),
                                                style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                            }, '🗑️'),
                                            row.tip === 'influencer' && h('button', {
                                                onClick: () => handleNavigate(row.id),
                                                style: { cursor: 'pointer', color: 'purple', border: 'none', background: 'none' }
                                            }, '👉🏻')
                                        )
                                    ];
                                }
                            })}
                            columns={['İsim', 'Soyisim', 'Telefon', 'Tip', 'Kupon Kodu', 'Aktif?', 'Oluşturma Zamanı', 'Action']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösteriliyor',
                                    'results': () => 'sonuçlar'
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>

    );
}
