import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";
import axios from "axios";

export default function KonuYBankDetail() {
    const { id } = useParams();
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [pdfFile, setPdfFile] = useState(null); // PDF dosyasını tutacak state
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`https://api.kpsskod.com/api/subjectBankSubject?subjectBank=${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.length === 0) {
                    alert('Veri Bulunamadı');
                    return;
                }

                const cityData = response.data.map(city => ({
                    id: city.id || '',
                    dersAdi: city.name || '',
                    ucret: city.price || '',
                    siralama: city.order || '',
                    aktif: city.isActive || false,
                    pdf: city.pdf || '', // PDF linkini ekleyelim
                    olusturmaZamani: city.createdTime || ''
                }));

                setGridData(cityData);

            } catch (error) {
                console.error('Veri getirirken bir hata oluştu:', error);
            }
        };

        fetchCities();
    }, [token, navigate]);

    function handleEdit(index) {
        setEditRowIndex(index);
    }

    function handleDelete(index) {
        const newData = [...gridData];
        newData.splice(index, 1);
        setGridData(newData);
    }

    function handleSave() {
        const row = gridData[editRowIndex];
        const isNewRow = !row.id;
        const url = isNewRow
            ? `https://api.kpsskod.com/api/subjectBankSubject?subjectBank=${id}`
            : `https://api.kpsskod.com/api/subjectBankSubject?subjectBank=${row.id}`;

        const method = isNewRow ? 'post' : 'put';

        axios({
            method: method,
            url: url,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                id: row.id,
                subject: row.subjectBank,
                name: row.dersAdi,
                order: row.siralama,
                price: row.ucret,
                pdf: row.pdf
            }
        })
            .then(response => {
                if (isNewRow) {
                    const newData = [...gridData];
                    newData[editRowIndex] = {
                        ...row,
                        olusturmaZamani: response.data.createdTime,
                        id: response.data.id
                    };
                    setGridData(newData);
                }
                setEditRowIndex(null);
            })
            .catch(error => {
                console.error('Veri kaydedilirken bir hata oluştu:', error);
            });
    }

    function handleCancel() {
        const row = gridData[editRowIndex];

        if (row.id) {
            axios.delete(`https://api.kpsskod.com/api/subjectBankSubject?subjectBank=${row.subject}&id=${row.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(() => {
                    const newData = [...gridData];
                    newData.splice(editRowIndex, 1);
                    setGridData(newData);
                    setEditRowIndex(null);
                })
                .catch(error => {
                    console.error('Veri silinirken bir hata oluştu:', error);
                });
        } else {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
            setEditRowIndex(null);
        }
    }

    function handleCancelNew() {
        if (editRowIndex === gridData.length - 1 && !gridData[editRowIndex].dersAdi && !gridData[editRowIndex].siralama) {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
        }
        setEditRowIndex(null);
    }

    function handleChange(event, key, index) {
        const newData = [...gridData];
        if (key === 'aktif') {
            newData[index][key] = event.target.checked;
        } else {
            newData[index][key] = event.target.value;
        }
        setGridData(newData);
    }

    function handleAddRow() {
        const newRow = {
            dersAdi: '',
            ucret: '',
            siralama: '',
            aktif: false,
            pdf: '',
            olusturmaZamani: new Date().toLocaleString()
        };
        setGridData([...gridData, newRow]);
        setEditRowIndex(gridData.length);
    }

    function handlePdfUpload(event, rowIndex) {
        const file = event.target.files[0]; // Yüklenen dosyayı al
        const formData = new FormData(); // FormData oluştur
        formData.append('photo', file); // API'nin istediği anahtar ismiyle dosyayı ekle (photo)

        axios.post('https://api.kpsskod.com/api/upload', formData, {
            headers: {
                Authorization: `Bearer ${token}`, // Bearer token ekleyin
                'Content-Type': 'multipart/form-data' // Doğru content type
            }
        })
            .then(response => {
                if (response.data.success) {
                    // Yanıt başarılı olduğunda gridData'yı güncelle
                    const updatedData = [...gridData];
                    updatedData[rowIndex].pdf = response.data.filename; // API'den dönen filename'i kullanarak PDF URL'sini ayarla
                    setGridData(updatedData); // State'i güncelle
                } else {
                    alert('PDF yüklenirken bir hata oluştu.');
                }
            })
            .catch(error => {
                console.error('PDF yüklenirken bir hata oluştu:', error); // Hata mesajını konsola yazdır
                alert('PDF yüklenirken bir hata oluştu.'); // Kullanıcıya hata mesajı göster
            });
    }


    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="/yetenek-home">Genel Yetenek</Link></li>
                            <li className="breadcrumb-item">Konu Bankası Detay</li>
                        </ol>
                        <h2 className="main-title mb-5">Konu Bankası Detay</h2>

                        <Button onClick={handleAddRow} className="mb-3">Yeni Konu Ekle +</Button>

                        <Grid
                            data={gridData.map((row, rowIndex) => {
                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('input', {
                                            value: row.dersAdi,
                                            onChange: (e) => handleChange(e, 'dersAdi', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.ucret,
                                            onChange: (e) => handleChange(e, 'ucret', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.siralama,
                                            onChange: (e) => handleChange(e, 'siralama', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            type: 'checkbox',
                                            checked: row.aktif,
                                            onChange: (e) => handleChange(e, 'aktif', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            type: 'file',
                                            accept: 'application/pdf',
                                            onChange: (e) => handlePdfUpload(e, rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        row.olusturmaZamani,
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                            }, '🗑️'),
                                            h('button', {
                                                onClick: handleCancelNew,
                                                style: { cursor: 'pointer', color: 'orange', border: 'none', background: 'none' }
                                            }, '❌')
                                        )
                                    ];
                                }
                                return [
                                    row.dersAdi,
                                    row.ucret,
                                    row.siralama,
                                    row.aktif ? 'Aktif' : 'Pasif',
                                    h('span', { style: { cursor: 'pointer' } }, h('a', { href: row.pdf, target: '_blank' }, 'PDF Görüntüle')),
                                    row.olusturmaZamani,
                                    h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                        h('button', {
                                            onClick: () => handleEdit(rowIndex),
                                            style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                        }, '✏️')
                                    )
                                ];
                            })}
                            columns={['Konu Adı', 'Ücret', 'Sıralama', 'Aktif', 'PDF', 'Oluşturma Zamanı', 'İşlemler']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösteriliyor',
                                    'results': () => 'sonuçlar'
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <Footer />
        </React.Fragment>
    );
}
