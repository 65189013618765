import React, { useState, useEffect } from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Avatar from "../components/Avatar";
import { dp1, dp2, dp3 } from "../data/DashboardData";
import Turkey from "@react-map/turkey";
import axios from 'axios';

import img10 from "../assets/img/img10.jpg";

export default function SalesMonitoring() {

  const [user, setUser] = useState({ name: '', lastname: '', type: '' });

  useEffect(() => {
    const nAme = localStorage.getItem('name');
    const lastName = localStorage.getItem('lastname');
    const tYpe = localStorage.getItem('type');

    if (nAme && lastName) {
      setUser({ name: nAme, lastname: lastName, type: tYpe });
    }
  }, []);

  const navigate = useNavigate();

  const [users, setUsers] = useState([]); // Moved useState out of useEffect

  useEffect(() => {
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');

    if (!token) {
      navigate('/pages/signin');
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://api.kpsskod.com/api/user?skip=0&take=10', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const limitedUsers = response.data.slice(0, 5); // Limit to first 5 users
        setUsers(limitedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [navigate]);



  const seriesOne = [{
    name: 'Growth',
    data: dp2
  }, {
    name: 'Actual',
    data: dp1
  }, {
    name: 'Plan',
    data: dp3
  }];

  const optionOne = {
    chart: {
      type: 'area',
      parentHeightOffset: 0,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ['#5575dc', '#81adee', '#ccd1ed'],
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: 1.5
    },
    fill: {
      type: 'gradient',
      opacity: 0.8,
      gradient: {
        type: 'vertical',
        shade: 'light',
        opacityFrom: 0.35,
        opacityTo: 0.65,
        stops: [0, 100]
      }
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    },
    yaxis: {
      max: 200,
      tickAmount: 6,
      show: false
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 13,
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '11px'
        }
      }
    }
  };

  const seriesTwo = [{
    data: [10, 12, 18, 25, 15, 30, 40, 10, 20, 12, 16, 60, 20, 15, 10, 60, 50, 40, 80, 100, 30, 40, 10, 20, 12, 16, 60, 20, 15, 60, 20, 15, 10, 60, 50, 40, 30, 40, 10, 20]
  }, {
    data: [-10, -12, -18, -25, -15, -30, -40, -10, -20, -12, -16, -60, -20, -15, -10, -60, -50, -40, -80, -40, -30, -40, -10, -20, -12, -16, -60, -20, -15, -60, -20, -15, -10, -60, -50, -40, -30, -40, -10, -20]
  }];

  const optionTwo = {
    chart: {
      height: 180,
      parentHeightOffset: 0,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: ['#506fd9', '#85b6ff'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    yaxis: {
      show: false
    },
    xaxis: {
      type: 'numeric',
      tickAmount: 10,
      decimalsInFloat: 0,
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '10px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      show: false
    },
    tooltip: {
      enabled: false
    }
  };

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }]
  };

  const chartOption = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: '#000',
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 10,
            weight: '500'
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#212830',
          font: {
            size: 12
          }
        }
      }
    }
  };

  const regStyle = {
    selected: {
      fill: "#506fd9"
    },
    initial: {
      fill: "#d9dde7"
    }
  };

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  if (skin === 'dark') {
    chartOption.scales['x'].grid.color = '#222b41';
    chartOption.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
    chartOption.scales['x'].grid.borderColor = '#222b41';
    chartOption.scales['y'].grid.color = '#222b41';
    chartOption.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
  } else {
    chartOption.scales['x'].grid.color = '#edeff6';
    chartOption.scales['x'].ticks.color = '#42484e';
    chartOption.scales['x'].grid.borderColor = '#edeff6';
    chartOption.scales['y'].grid.color = '#edeff6';
    chartOption.scales['y'].ticks.color = '#42484e';
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item active" aria-current="page">Anasayfa</li>
            </ol>
            <h4 className="main-title mb-0">Hoşgeldin, {user.name} {user.lastname}</h4>
          </div>
        </div>

        <Row className="g-3">
          {[
            {
              "label": "Genel Yetenek Konu Bankası",
              "icon": "ri-shopping-bag-3-line",
              "value": "827",
              "status": "down"
            }, {
              "label": "Genel Kültür Soru Bankası",
              "icon": "ri-shopping-bag-3-line",
              "value": "1205",
              "status": "up"
            }, {
              "label": "Genel Yetenek Konu Bankası",
              "icon": "ri-shopping-bag-3-line",
              "value": "498",
              "status": "down"
            }, {
              "label": "Genel yetenek Soru Bankası",
              "icon": "ri-shopping-bag-3-line",
              "value": "628",
              "status": "up"
            }
          ].map((card, index) => (
            <Col xs="6" xl="3" key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
                  <h3 className="card-value mb-1"><i className={card.icon}></i> {card.value}</h3>
                </Card.Body>
              </Card>
            </Col>
          ))}
          <Col xl="7">
            <Card className="card-one card-vmap">
              <Card.Header>
                <Card.Title as="h6">Kullanıcıların Bulunduğu İl</Card.Title>

              </Card.Header>
              <Card.Body className="p-3 p-xl-4 crdBodGrd">
                <Turkey className="dashTrMap" hoverColor="orange" type='select-single' />

              </Card.Body>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Kullanıcılar</Card.Title>

              </Card.Header>
              <Card.Body className="p-0">
                <ul className="people-group">
                  {users.map((user, index) => (
                    <li className="people-item" key={index}>
                      <Avatar img={user.avatar || img10} /> { }
                      <div className="people-body">
                        <h6>{user.name + " " + user.lastname}</h6>
                        <span>Kullanıcı E-Mail: <strong>{user.email}</strong> </span>
                      </div>
                      <Nav as="nav" className="nav-icon">
                        <div className="people-body">
                          <span>{user.type}</span>
                        </div>

                      </Nav>
                    </li>
                  ))}
                </ul>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-center">
                <Link href="/users-new" className="fs-sm">Kullanıcıları Görüntüle</Link>
              </Card.Footer>
            </Card>
          </Col>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
}