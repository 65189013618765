import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";
import axios from "axios";

export default function SoruYBankDetail() {
    const { id } = useParams();
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [gridData, setGridData] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`https://api.kpsskod.com/api/lesson/detail?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.length === 0) {
                    alert('Veri Bulunamadı');
                    return;
                }

                const cityData = [{
                    id: response.data._id || '',
                    lessonSubject: response.data.lessonSubject || '',
                    type: response.data.type || '',
                    dersAdi: response.data.name || '',
                    ucret: response.data.price || '',
                    siralama: response.data.order || '',
                    aktif: response.data.isActive || false,
                    olusturmaZamani: response.data.createdTime || ''
                }];

                setGridData(cityData);

            } catch (error) {
                console.error('Veri getirirken bir hata oluştu:', error);
            }
        };

        fetchCities();
    }, [token, navigate,]);

    function handleEdit(index) {
        setEditRowIndex(index);
    }

    function handleDelete(index) {
        const newData = [...gridData];
        newData.splice(index, 1);
        setGridData(newData);
    }

    function handleSave() {
        const row = gridData[editRowIndex];
        const isNewRow = !row.id;
        const url = `https://api.kpsskod.com/api/lessonSubjectQuestion?lessonSubject=${id}`;

        const method = isNewRow ? 'post' : 'put';

        axios({
            method: method,
            url: url,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                name: row.dersAdi,
                type: 'gy',
                order: row.siralama,
                price: row.ucret,
            }
        })
            .then(response => {
                if (isNewRow) {
                    const newData = [...gridData];
                    newData[editRowIndex] = {
                        ...row,
                        olusturmaZamani: response.data.createdTime,
                        id: response.data.id
                    };
                    setGridData(newData);
                }
                setEditRowIndex(null);
            })
            .catch(error => {
                console.error('Veri kaydedilirken bir hata oluştu:', error);
            });
    }

    function handleCancel() {
        const row = gridData[editRowIndex];

        if (row.id) {
            axios.delete(`https://api.kpsskod.com/api/lesson?id=${row.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(() => {
                    const newData = [...gridData];
                    newData.splice(editRowIndex, 1);
                    setGridData(newData);
                    setEditRowIndex(null);
                })
                .catch(error => {
                    console.error('Veri silinirken bir hata oluştu:', error);
                });
        } else {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
            setEditRowIndex(null);
        }
    }

    function handleCancelNew() {
        if (editRowIndex === gridData.length - 1 && !gridData[editRowIndex].dersAdi && !gridData[editRowIndex].siralama) {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
        }
        setEditRowIndex(null);
    }
    function handleNavigate(index) {
        navigate(`/genel-yetenek/soru-bankasi-detail-soru/${index}`);
    }
    function handleChange(event, key, index) {
        const newData = [...gridData];
        if (key === 'aktif') {
            newData[index][key] = event.target.checked;
        } else {
            newData[index][key] = event.target.value;
        }
        setGridData(newData);
    }

    function handleAddRow() {
        const newRow = {
            dersAdi: '',
            ucret: '',
            siralama: '',
            aktif: false,
            olusturmaZamani: new Date().toLocaleString()
        };
        setGridData([...gridData, newRow]);
        setEditRowIndex(gridData.length);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="/yetenek-home">Genel Yetenek</Link></li>
                            <li className="breadcrumb-item">Soru Bankası Detay</li>
                        </ol>
                        <h2 className="main-title mb-5">Soru Bankası Detay</h2>

                        <Button onClick={handleAddRow} className="mb-3">Yeni Konu Ekle +</Button>

                        <Grid
                            data={gridData.map((row, rowIndex) => {
                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('input', {
                                            value: row.dersAdi,
                                            onChange: (e) => handleChange(e, 'dersAdi', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.ucret,
                                            onChange: (e) => handleChange(e, 'ucret', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.siralama,
                                            onChange: (e) => handleChange(e, 'siralama', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            type: 'checkbox',
                                            checked: row.aktif,
                                            onChange: (e) => handleChange(e, 'aktif', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        row.olusturmaZamani,
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '🗑️'),
                                            h('button', {
                                                onClick: handleCancelNew,
                                                style: { cursor: 'pointer', color: 'orange', border: 'none', background: 'none' }
                                            }, '❌')
                                        )
                                    ];
                                } else {
                                    return [
                                        row.dersAdi || 'Veri Yok',  // Veri yoksa 'Veri Yok' yazısı göster
                                        row.ucret || 'Veri Yok',
                                        row.siralama || 'Veri Yok',
                                        row.aktif ? '✅' : '❌',
                                        row.olusturmaZamani || 'Veri Yok',
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: () => handleEdit(rowIndex),
                                                style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                            }, '✏️'),
                                            h('button', {
                                                onClick: () => handleNavigate(row.id),
                                                style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                            }, '👉🏻')
                                        )
                                    ];
                                }
                            })}
                            columns={['Konu Adı', 'Konu Ücreti', 'Sıralama', 'Aktif?', 'Oluşturma Zamanı', 'Action']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösteriliyor',
                                    'results': () => 'sonuçlar'
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}
