import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";
import axios from "axios";

export default function BilgiKartiDetail() {
    const { id, name } = useParams();
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [gridData, setGridData] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        console.log(name);

        const fetchCities = async () => {
            try {
                const response = await axios.get(`https://api.kpsskod.com/api/infoCard`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const cityData = response.data.map(city => ({
                    id: city.id || '',
                    dersAdi: city.question || '',
                    ucret: city.answer || '',
                    order: city.order || '',
                    siralama: city.infoCardGroup || '',
                    aktif: city.isActive || false,
                    imgUrl: city.img || '',  // Resim URL'si burada saklanacak
                    olusturmaZamani: city.createdTime || ''
                }));

                setGridData(cityData);

            } catch (error) {
                console.error('Veri getirirken bir hata oluştu:', error);
            }
        };

        fetchCities();
    }, [token, navigate]);

    function handleEdit(index) {
        setEditRowIndex(index);
    }

    function handleDelete(index) {
        const newData = [...gridData];
        newData.splice(index, 1);
        setGridData(newData);
    }

    function handleSave() {
        const row = gridData[editRowIndex];  // Düzenlenen satırı al
        const isNewRow = !row.id;  // Yeni satır mı değil mi kontrol et

        // Yeni kayıt için URL ve metod belirleme
        const url = isNewRow
            ? 'https://api.kpsskod.com/api/infoCard'
            : `https://api.kpsskod.com/api/infoCard`;

        const method = isNewRow ? 'post' : 'put';

        // Verileri API'ye uygun formatta gönderiyoruz
        const requestData = {
            question: row.dersAdi || row.question,  // Eğer soruyu buradan alıyorsanız
            answer: row.ucret || row.answer,        // Cevabı burada setliyoruz
            img: row.imgUrl || '',                 // Yeni URL alanı
            infoCardGroup: row.siralama || row.infoCardGroup,  // Grup bilgisi
            order: row.order || 0  // Sıralama numarası
        };

        axios({
            method: method,
            url: url,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: requestData
        })
            .then(response => {
                const updatedData = [...gridData];
                updatedData[editRowIndex] = {
                    ...row,
                    olusturmaZamani: response.data.createdTime
                };
                setGridData(updatedData);  // gridData'yı güncelle
                setEditRowIndex(null);     // Düzenleme modunu kapat
            })
            .catch(error => {
                console.error('Veri kaydedilirken bir hata oluştu:', error);
            });
    }

    function handleCancel() {
        const row = gridData[editRowIndex];

        if (row.id) {
            axios.delete(`https://api.kpsskod.com/api/infoCard?id=${row.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(() => {
                    const newData = [...gridData];
                    newData.splice(editRowIndex, 1);
                    setGridData(newData);
                    setEditRowIndex(null);
                })
                .catch(error => {
                    console.error('Veri silinirken bir hata oluştu:', error);
                });
        } else {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
            setEditRowIndex(null);
        }
    }

    function handleCancelNew() {
        if (editRowIndex === gridData.length - 1 && !gridData[editRowIndex].dersAdi && !gridData[editRowIndex].siralama) {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
        }
        setEditRowIndex(null);
    }

    function handleChange(event, key, index) {
        const newData = [...gridData];
        if (key === 'aktif') {
            newData[index][key] = event.target.checked;
        } else {
            newData[index][key] = event.target.value;
        }
        setGridData(newData);
    }

    function handleAddRow() {
        const newRow = {
            dersAdi: '',
            ucret: '',
            order: gridData.length + 1,  // Yeni bir sıra numarası belirleyin
            siralama: id,  // Grubun ID'si burada belirlenmiş olmalı
            aktif: false,
            imgUrl: '',  // Yeni img URL alanı için başlangıç değeri
            olusturmaZamani: new Date().toLocaleString()
        };
        setGridData([...gridData, newRow]);
        setEditRowIndex(gridData.length);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="">Bilgi Kartı Detay</Link></li>
                        </ol>
                        <h2 className="main-title mb-5">Bilgi Kartı Detay</h2>

                        <Button onClick={handleAddRow} className="mb-3">Yeni Bilgi Kartı Ekle +</Button>

                        <Grid
                            data={gridData.map((row, rowIndex) => {
                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('input', {
                                            value: row.dersAdi,
                                            onChange: (e) => handleChange(e, 'dersAdi', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            value: row.ucret,
                                            onChange: (e) => handleChange(e, 'ucret', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            placeholder: name,
                                            value: row.siralama,
                                            onChange: (e) => handleChange(e, 'siralama', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('input', {
                                            type: 'text',  // Yeni URL input alanı
                                            value: row.imgUrl,
                                            onChange: (e) => handleChange(e, 'imgUrl', rowIndex),
                                            placeholder: 'Resim URL girin',
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            type: 'checkbox',
                                            checked: row.aktif,
                                            onChange: (e) => handleChange(e, 'aktif', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        row.olusturmaZamani,
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                            }, '🗑️'),
                                            h('button', {
                                                onClick: handleCancelNew,
                                                style: { cursor: 'pointer', color: 'orange', border: 'none', background: 'none' }
                                            }, '❌')
                                        )
                                    ];
                                }
                                return [
                                    row.dersAdi,
                                    row.ucret,
                                    name,
                                    row.aktif ? '✅' : '❌',
                                    h('span', { style: { cursor: 'pointer' } },
                                        h('img', {
                                            src: row.imgUrl,  // imgUrl alanını kullanarak resmi göster
                                            alt: 'Yüklenmedi',
                                            style: { maxWidth: '50px', height: 'auto' }
                                        })
                                    ),
                                    row.olusturmaZamani,
                                    h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                        h('button', {
                                            onClick: () => handleEdit(rowIndex),
                                            style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                        }, '✏️')
                                    )
                                ];
                            })}
                            columns={['Soru', 'Cevap', 'Konu', 'Aktif', 'Resim', 'Oluşturma Zamanı', 'İşlemler']}
                            search={true}
                            pagination={{ enabled: true, limit: 10 }}
                            resizable={true}
                        />
                    </Col>
                </Row>
            </div>
            <Footer />
        </React.Fragment>
    );
}
