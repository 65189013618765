import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";

export default function CitiesDetailNew() {

    function handleEdit(rowData) {
        alert("Edit clicked for: " + JSON.stringify(rowData));
    }

    function handleDelete(rowData) {
        alert("Redirect clicked for: " + JSON.stringify(rowData));
    }

    const gridData = [
        ['İzmir', '35', '✅', '08.05.00:39'],
        ['Amasya', '05', '✖️', '08.05.00:39'],
    ];

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="#">İller</Link></li>
                        </ol>
                        <h2 className="main-title mb-3">İller</h2>

                        <p className="text-secondary mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <Grid
                            data={gridData.map((row, index) => [
                                ...row,
                                h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                    h('button', {
                                        onClick: () => handleEdit(row),
                                        style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                    }, '✏️'),
                                    h('button', {
                                        onClick: () => handleDelete(row),
                                        style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                    }, '👉🏻')
                                )
                            ])}
                            columns={['İl Adı', 'Sıralama', 'Aktif?', 'Oluşturma Zamanı', 'Action']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösteriliyor',
                                    'results': () => 'sonuçlar'
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}
