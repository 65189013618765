import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import "gridjs/dist/theme/mermaid.css";
import axios from "axios";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export default function BilgiYBankDetail() {
    const { id } = useParams();
    const [editorContent, setEditorContent] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const response = await axios.get(
                    `https://api.kpsskod.com/api/databankInfo/detail?id=${id}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                setEditorContent(response.data.name || '');
            } catch (error) {
                console.error('Error fetching content:', error);
            }
        };

        fetchContent();
    }, [id, token]);

    const handleSave = async () => {
        try {
            const response = await axios.post(
                'https://api.kpsskod.com/api/databankInfo/updateAbout',
                {
                    name: editorContent,
                    id: id
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            alert('Veri Kaydedildi');
        } catch (error) {
            console.error('Error saving content:', error);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="/yetenek-home">Genel Yetenek</Link></li>
                            <li className="breadcrumb-item">Bilgi Bankası Detay</li>
                        </ol>
                        <h2 className="main-title mb-5">Metin Düzenleyici</h2>

                        <ReactQuill
                            value={editorContent}
                            onChange={setEditorContent}
                            theme="snow"
                        />

                        <Button onClick={handleSave} className="mt-3">Kaydet</Button>
                    </Col>
                </Row>
            </div>
            <Footer />
        </React.Fragment>
    );
}
