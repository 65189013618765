import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { h } from "gridjs";
import axios from "axios";

export default function SoruYBankDetailSoruDetail() {
    const { id } = useParams();
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [gridData, setGridData] = useState([]);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchAnswers = async () => {
            try {
                const response = await axios.get(`https://api.kpsskod.com/api/lessonSubjectQuestion?lessonSubject=${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const formattedData = response.data.map(item => ({
                    id: item.id || '',
                    lessonSubject: item.lessonSubject || '',
                    question: item.question || '',
                    answers: item.answers.map(answer => ({
                        answer: answer.answer || '',
                        correct: answer.correct || false,
                        order: answer.order || 0,
                        _id: answer._id || ''  // _id ekleniyor
                    })) || [],
                    isActive: item.isActive || false,
                    order: item.order || ''
                }));

                setGridData(formattedData);

            } catch (error) {
                console.error('Veri getirirken bir hata oluştu:', error);
            }
        };

        fetchAnswers();
    }, [token, id]);

    function handleEdit(index) {
        setEditRowIndex(index);
    }

    function handleDelete(index) {
        const newData = [...gridData];
        newData.splice(index, 1);
        setGridData(newData);
    }

    function handleSave() {
        const row = gridData[editRowIndex];
        const isNewRow = !row.id;

        const url = isNewRow
            ? `https://api.kpsskod.com/api/lessonSubjectQuestion?lessonSubject=${id}`
            : `https://api.kpsskod.com/api/lessonSubjectQuestion?lessonSubject=${row.lessonSubject}&id=${id}`;

        const method = isNewRow ? 'post' : 'put';

        // API'ye gönderilecek veri
        const requestData = {
            question: row.question.trim(),
            order: row.order,
            answers: row.answers.map(answer => ({
                answer: answer.answer.trim(),
                correct: answer.correct,
                order: answer.order
            })).filter(answer => answer.answer) // Boş cevapları filtrele
        };

        axios({
            method: method,
            url: url,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: requestData
        })
            .then(response => {
                console.log('Yanıt verisi:', response.data); // API'den dönen yanıtı logla
                const newData = [...gridData];
                if (isNewRow) {
                    newData[editRowIndex] = {
                        ...row,
                        id: response.data.id // Yeni satır için ID API'den gelen ID ile güncelleniyor
                    };
                }
                setGridData(newData);
                setEditRowIndex(null);
            })
            .catch(error => {
                console.error('Veri kaydedilirken bir hata oluştu:', error);
                console.error('Hata ayrıntıları:', error.response ? error.response.data : error.message);
            });
    }



    function handleCancel() {
        const row = gridData[editRowIndex];

        if (row.id) {
            axios.delete(`https://api.kpsskod.com/api//lessonSubjectQuestion?lessonSubject=${row.lessonSubject}&id=${row.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(() => {
                    const newData = [...gridData];
                    newData.splice(editRowIndex, 1);
                    setGridData(newData);
                    setEditRowIndex(null);
                })
                .catch(error => {
                    console.error('Veri silinirken bir hata oluştu:', error);
                });
        } else {
            const newData = [...gridData];
            newData.splice(editRowIndex, 1);
            setGridData(newData);
            setEditRowIndex(null);
        }
    }

    function handleChange(event, key, index) {
        const newData = [...gridData];
        if (key.startsWith('answers.')) {
            const [_, answerIndex, answerKey] = key.split('.');
            newData[index].answers[answerIndex][answerKey] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        } else if (key === 'isActive') {
            newData[index][key] = event.target.checked;
        } else {
            newData[index][key] = event.target.value;
        }
        setGridData(newData);
    }


    function handleAddRow() {
        const newRow = {
            id: '',  // Yeni satır için ID boş bırakılıyor
            lessonSubject: id,  // Yeni satırın lessonSubject değerini URL parametresinden al
            question: id,
            answers: ['', '', '', '', ''].map((_, i) => ({
                answer: '',
                correct: false,
                order: i,
                _id: ''  // Yeni satır için _id boş bırakılıyor
            })),
            isActive: false,
            order: ''
        };
        setGridData([...gridData, newRow]);
        setEditRowIndex(gridData.length);
    }


    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item">Soru Bankası Detay Soru</li>
                        </ol>
                        <h2 className="main-title mb-5">Soru Bankası Cevaplar</h2>

                        <Button onClick={handleAddRow} className="mb-3">Yeni Cevap Ekle +</Button>
                        <Grid
                            data={gridData.map((row, rowIndex) => {
                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('textarea', {
                                                    value: answer.answer,
                                                    onChange: (e) => handleChange(e, `answers.${index}.answer`, rowIndex),
                                                    style: { width: '100%', height: '30px', display: 'block', marginBottom: '5px' }
                                                })
                                            )
                                        ),
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('input', {
                                                    type: 'number',
                                                    value: answer.order,
                                                    onChange: (e) => handleChange(e, `answers.${index}.order`, rowIndex),
                                                    style: { width: '100%', height: '30px', display: 'block', marginBottom: '5px' }
                                                })
                                            )
                                        ),
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('input', {
                                                    type: 'checkbox',
                                                    checked: answer.correct,
                                                    onChange: (e) => handleChange(e, `answers.${index}.correct`, rowIndex),
                                                    style: { width: '100%', display: 'block', marginBottom: '5px' }
                                                })
                                            )
                                        ),
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'red', border: 'none', background: 'none' }
                                            }, '🗑️'),
                                        )
                                    ];
                                } else {
                                    return [
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('div', { key: index, style: { marginBottom: '5px' } }, answer.answer)
                                            )
                                        ),
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('div', { key: index, style: { marginBottom: '5px' } }, answer.order)
                                            )
                                        ),
                                        h('div', {},
                                            row.answers.map((answer, index) =>
                                                h('div', { key: index, style: { marginBottom: '5px' } }, answer.correct ? '✅' : '❌')
                                            )
                                        ),
                                        h('div', { style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: () => handleEdit(rowIndex),
                                                style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                            }, '✏️')
                                        )
                                    ];
                                }
                            })}
                            columns={['Cevaplar', 'Sıralama', 'Aktif?', 'İşlem']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösterilen',
                                    'results': () => 'kayıt'
                                }
                            }}
                        />


                    </Col>
                </Row>
            </div>
            <Footer />
        </React.Fragment>
    );
}
