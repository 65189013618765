import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

export default function Signin() {
  const [telefonNumarasi, setTelefonNumarasi] = useState('');
  const [sifre, setSifre] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleTelefonNumarasiChange = (e) => setTelefonNumarasi(e.target.value);
  const handleSifreChange = (e) => setSifre(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!telefonNumarasi || !sifre) {
      setError('Telefon numarası ve şifre gerekli');
      return;
    }

    try {
      const response = await axios.post('https://api.kpsskod.com/api/user/adminLogin', {
        gsm: telefonNumarasi,
        password: sifre
      });

      const { token, name, lastname, id, type } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
      localStorage.setItem('name', name);
      localStorage.setItem('lastname', lastname);
      localStorage.setItem('type', type);

      navigate('/');

    } catch (error) {
      setError('Yanlış kullanıcı adı veya şifre');
    }
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">DexaDev</Link>
          <Card.Text>Tekrar hoş geldiniz! Devam etmek için lütfen giriş yapın.</Card.Text>
        </Card.Header>
        <Card.Body>
          {error && <div className="alert alert-danger">{error}</div>} {/* Hata mesajı */}
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Telefon Numarası</Form.Label>
              <Form.Control
                type="text"
                placeholder="Telefon Numarası Giriniz"
                value={telefonNumarasi}
                onChange={handleTelefonNumarasiChange}
              />
            </div>
            <div className="mb-4">
              <Form.Control
                type="password"
                placeholder="Şifre Giriniz"
                value={sifre}
                onChange={handleSifreChange}
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">Giriş</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
