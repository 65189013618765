import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import "gridjs/dist/theme/mermaid.css";

export default function Yapim() {


    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item">Yapım Aşamasında!</li>
                        </ol>
                        <h2 className="main-title mb-5">Yapım Aşamasında!</h2>

                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
