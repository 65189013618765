import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import axios from 'axios';
import { h } from 'preact'; // Prettier veya h kütüphanesini buraya ekleyin.

export default function UsersDetailNew() {
    const { id } = useParams();
    const [gridData, setGridData] = useState([]);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const navigate = useNavigate(); // useNavigate'ı bileşen işlevi içinde kullanın.

    useEffect(() => {
        const token = localStorage.getItem('token');

        const fetchUsers = async () => {
            try {
                const response = await axios.get(`https://api.kpsskod.com/api/userBuy/infulencerList?id=${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.length === 0) {
                    navigate('/404'); // ID eşleşmiyorsa 404 sayfasına yönlendir.
                    return;
                }

                const userDetailData = response.data.map(user => ({
                    kuponKodu: user.coupon || ' Yok',
                    satisFiyati: user.price || '',
                    indirim: user.influencerDiscount || '',
                    tip: user.type || '',
                    urun: user.order > 0 ? user.order : 'Yok',
                    odendiMi: user.payedToInfluencer || false,
                    olusturmaZamani: user.createdTime || '',
                    id: user.id,
                    aktif: user.active || false,
                    isim: user.firstName || '',
                    soyisim: user.lastName || '',
                    telefon: user.phone || '',
                }));

                setGridData(userDetailData);

            } catch (error) {
                console.error('Kullanıcıları getirirken bir hata oluştu:', error);
            }
        };

        fetchUsers();
    }, [id]);

    const handleEdit = (index) => {
        setEditRowIndex(index);
    };


    const handleSave = () => {
        setEditRowIndex(null);
    };

    const handleCancel = () => {
        setEditRowIndex(null);
    };

    const handleChange = (event, key, index) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setGridData(prevData => prevData.map((item, i) =>
            i === index ? { ...item, [key]: value } : item
        ));
    };

    const counties = ['County1', 'County2', 'County3']; // Örnek ilçeler, kendi verilerinize göre düzenleyin.

    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item"><Link to="/users-new">Kullanıcılar</Link></li>
                            <li className="breadcrumb-item">Barış Şimşek</li>
                        </ol>
                        <h2 className="main-title mb-5">Kullanıcı Detay</h2>
                        <Grid
                            data={gridData.map((row, rowIndex) => {
                                if (rowIndex === editRowIndex) {
                                    return [
                                        h('input', {
                                            key: 'kuponKodu',
                                            value: row.kuponKodu,
                                            onChange: (e) => handleChange(e, 'kuponKodu', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('input', {
                                            key: 'satisFiyati',
                                            value: row.satisFiyati,
                                            onChange: (e) => handleChange(e, 'satisFiyati', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('input', {
                                            key: 'indirim',
                                            value: row.indirim,
                                            onChange: (e) => handleChange(e, 'indirim', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('select', {
                                            key: 'tip',
                                            value: row.tip,
                                            onChange: (e) => handleChange(e, 'tip', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }, counties.map(county =>
                                            h('option', { value: county, key: county }, county)
                                        )),
                                        h('input', {
                                            key: 'urun',
                                            value: row.urun,
                                            onChange: (e) => handleChange(e, 'urun', rowIndex),
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('input', {
                                            key: 'odendiMi',
                                            type: 'checkbox',
                                            checked: row.odendiMi,
                                            onChange: (e) => handleChange(e, 'odendiMi', rowIndex),
                                            style: { width: '100%' }
                                        }),
                                        h('input', {
                                            key: 'olusturmaZamani',
                                            value: row.olusturmaZamani,
                                            readOnly: true,
                                            style: { width: '100%', backgroundColor: '#e9ecef', border: '1px solid #ced4da', pointerEvents: 'none' }
                                        }),
                                        h('div', { key: 'actions', style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: handleSave,
                                                style: { cursor: 'pointer', color: 'green', border: 'none', background: 'none' }
                                            }, '💾'),
                                            h('button', {
                                                onClick: handleCancel,
                                                style: { cursor: 'pointer', color: 'orange', border: 'none', background: 'none' }
                                            }, '❌')
                                        )
                                    ];
                                } else {
                                    return [
                                        row.kuponKodu,
                                        row.satisFiyati,
                                        row.indirim,
                                        row.tip,
                                        row.urun,
                                        row.odendiMi ? '✅' : '❌',
                                        row.olusturmaZamani,
                                        h('div', { key: 'actions', style: { display: 'flex', justifyContent: 'space-around' } },
                                            h('button', {
                                                onClick: () => handleEdit(rowIndex),
                                                style: { cursor: 'pointer', color: 'blue', border: 'none', background: 'none' }
                                            }, '✏️')
                                        )
                                    ];
                                }
                            })}
                            columns={['Kupon Kodu', 'Satış Fiyatı', 'İndirim', 'Tip', 'Ürün', 'Ödendi mi?', 'Oluşturma Zamanı', 'Aksiyon']}
                            search={true}
                            pagination={true}
                            className={{
                                table: 'table table-bordered mb-0'
                            }}
                            language={{
                                'search': {
                                    'placeholder': 'Ara...'
                                },
                                'pagination': {
                                    'previous': 'Önceki',
                                    'next': 'Sonraki',
                                    'showing': 'Gösteriliyor',
                                    'results': () => 'sonuçlar'
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}
