import React from "react";

// Dashboard
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagement from "../dashboard/ProductManagement";

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";
import UsersNew from "../pages/UsersNew";
import UsersDetailNew from "../pages/UsersDetailNew";
import CitiesNew from "../pages/CitiesNew";
import CitiesDetailNew from "../pages/CitiesDetailNew";
import DistricNew from "../pages/DistricNew";
import KulturHome from "../pages/KulturHome";
import YetenekHome from "../pages/YetenekHome";

import BilgiBank from "../pages/genel-kultur/BilgiBank";
import KonuBank from "../pages/genel-kultur/KonuBank";
import KonuBankDetail from "../pages/genel-kultur/KonuBankDetail";
import SoruBank from "../pages/genel-kultur/SoruBank";
import SoruBankDetail from "../pages/genel-kultur/SoruBankDetail";
import SoruBankDetailSoru from "../pages/genel-kultur/SoruBankDetailSoru";
import SoruBankDetailSoruDetail from "../pages/genel-kultur/SoruBankDetailSoruDetail";
import BilgiBankDetail from "../pages/genel-kultur/BilgiBankDetail";
import KonuBankDetailSoru from "../pages/genel-kultur/KonuBankDetailSoru";

import BilgiYBank from "../pages/genel-yetenek/BilgiYBank";
import SoruYBankDetailSoruDetail from "../pages/genel-yetenek/SoruYBankDetailSoruDetail";
import BilgiYBankDetail from "../pages/genel-yetenek/BilgiYBankDetail";
import KonuYBank from "../pages/genel-yetenek/KonuYBank";
import KonuYBankDetail from "../pages/genel-yetenek/KonuYBankDetail";
import SoruYBankDetail from "../pages/genel-yetenek/SoruYBankDetail";
import SoruYBankDetailSoru from "../pages/genel-yetenek/SoruYBankDetailSoru";
import SoruYBank from "../pages/genel-yetenek/SoruYBank";

import Setting from "../pages/Setting";
import Yapim from "../pages/Yapim";

import BilgiEBank from "../pages/egitim-bilimleri/BilgiEBank";
import BilgiEBankDetail from "../pages/egitim-bilimleri/BilgiEBankDetail";
import KonuEBank from "../pages/egitim-bilimleri/KonuEBank";
import KonuEBankDetail from "../pages/egitim-bilimleri/KonuEBankDetail";
import KonuEBankDetailSoru from "../pages/egitim-bilimleri/KonuEBankDetailSoru";
import SoruEBank from "../pages/egitim-bilimleri/SoruEBank";
import SoruEBankDetail from "../pages/egitim-bilimleri/SoruEBankDetail";
import SoruEBankDetailSoru from "../pages/egitim-bilimleri/SoruEBankDetailSoru";
import SoruEBankDetailSoruDetail from "../pages/egitim-bilimleri/SoruEBankDetailSoruDetail";

import BilgiKarti from "../pages/bilgi-karti/BilgiKarti";
import BilgiKartiDetail from "../pages/bilgi-karti/BilgiKartiDetail";
import BilgiKartiDetDetail from "../pages/bilgi-karti/BilgiKartiDetDetail";

import Sss from "../pages/Sss";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";
import Distric from "../pages/DistricNew";

const protectedRoutes = [
  { path: "/users-new", element: <UsersNew /> },
  { path: "/users-detail-new/:ids", element: <UsersDetailNew /> },
  { path: "/cities-new", element: <CitiesNew /> },
  { path: "/cities-detail-new", element: <CitiesDetailNew /> },
  { path: "/district-new", element: <DistricNew /> },
  { path: "/kultur-home", element: <KulturHome /> },
  { path: "/yetenek-home", element: <YetenekHome /> },
  { path: "/setting", element: <Setting /> },

  { path: "/yapim", element: <Yapim /> },

  { path: "/genel-kultur/bilgi-bankasi", element: <BilgiBank /> },
  { path: "/genel-kultur/konu-bankasi", element: <KonuBank /> },
  { path: "/genel-kultur/soru-bankasi", element: <SoruBank /> },

  { path: "/genel-kultur/soru-bankasi-detail/:id", element: <SoruBankDetail /> },
  { path: "/genel-kultur/soru-bankasi-detail-soru/:id", element: <SoruBankDetailSoru /> },
  { path: "/genel-kultur/soru-bankasi-detail-soru-detail/:id", element: <SoruBankDetailSoruDetail /> },
  { path: "/genel-kultur/konu-bankasi-detail/:id", element: <KonuBankDetail /> },
  { path: "/genel-yetenek/konu-bankasi-detail/:id", element: <KonuYBankDetail /> },
  { path: "/genel-kultur/konu-bankasi-detail-soru/:id", element: <KonuBankDetailSoru /> },
  { path: "/genel-yetenek/soru-bankasi-detail/:id", element: <SoruYBankDetail /> },
  { path: "/genel-yetenek/soru-bankasi-detail-soru/:id", element: <SoruYBankDetailSoru /> },
  { path: "/genel-yetenek/soru-bankasi-detail-soru-detail/:id", element: <SoruYBankDetailSoruDetail /> },

  { path: "/genel-yetenek/bilgi-bankasi", element: <BilgiYBank /> },
  { path: "/genel-yetenek/bilgi-bankasi-detail/:id", element: <BilgiYBankDetail /> },
  { path: "/genel-kultur/bilgi-bankasi-detail/:id", element: <BilgiBankDetail /> },
  { path: "/genel-yetenek/konu-bankasi", element: <KonuYBank /> },
  { path: "/genel-yetenek/soru-bankasi", element: <SoruYBank /> },

  { path: "/egitim-bilimleri/bilgi-bankasi", element: <BilgiEBank /> },
  { path: "/egitim-bilimleri/bilgi-bankasi-detail/:id", element: <BilgiEBankDetail /> },
  { path: "/egitim-bilimleri/konu-bankasi", element: <KonuEBank /> },
  { path: "/egitim-bilimleri/konu-bankasi-detail/:id", element: <KonuEBankDetail /> },
  { path: "/egitim-bilimleri/konu-bankasi-detail-soru/:id", element: <KonuEBankDetailSoru /> },
  { path: "/egitim-bilimleri/soru-bankasi", element: <SoruEBank /> },
  { path: "/egitim-bilimleri/soru-bankasi-detail/:id", element: <SoruEBankDetail /> },
  { path: "/egitim-bilimleri/soru-bankasi-detail-soru/:id", element: <SoruEBankDetailSoru /> },
  { path: "/egitim-bilimleri/soru-bankasi-detail-soru-detail/:id", element: <SoruEBankDetailSoruDetail /> },

  { path: "/bilgi-karti/bilgi-karti", element: <BilgiKarti /> },
  { path: "/bilgi-karti/bilgi-karti-detail/:id/:name", element: <BilgiKartiDetail /> },
  { path: "/bilgi-karti/bilgi-karti-det-detail/:id", element: <BilgiKartiDetDetail /> },

  { path: "/sss", element: <Sss /> },

  { path: "dashboard/finance", element: <FinanceMonitoring /> },
  { path: "dashboard/events", element: <EventManagement /> },
  { path: "/", element: <SalesMonitoring /> },
  { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  { path: "dashboard/crypto", element: <Cryptocurrency /> },
  { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  { path: "dashboard/storage", element: <StorageManagement /> },
  { path: "dashboard/product", element: <ProductManagement /> },
  { path: "apps/gallery-music", element: <GalleryMusic /> },
  { path: "apps/gallery-video", element: <GalleryVideo /> },
  { path: "apps/tasks", element: <Tasks /> },
  { path: "apps/contacts", element: <Contacts /> },
  { path: "apps/chat", element: <Chat /> },
  { path: "apps/calendar", element: <AppCalendar /> },
  { path: "apps/email", element: <Email /> },
  { path: "apps/file-manager", element: <FileManager /> },
  { path: "pages/pricing", element: <Pricing /> },
  { path: "pages/faq", element: <Faq /> },
  { path: "pages/profile", element: <Profile /> },
  { path: "pages/people", element: <People /> },
  { path: "pages/activity", element: <Activity /> },
  { path: "pages/events", element: <Events /> },
  { path: "pages/settings", element: <Settings /> },
  { path: "docs/layout/grid", element: <LayoutGrid /> },
  { path: "docs/layout/columns", element: <LayoutColumns /> },
  { path: "docs/layout/gutters", element: <LayoutGutters /> },
  { path: "docs/com/accordions", element: <Accordions /> },
  { path: "docs/com/alerts", element: <Alerts /> },
  { path: "docs/com/avatars", element: <Avatars /> },
  { path: "docs/com/badge", element: <Badges /> },
  { path: "docs/com/breadcrumbs", element: <Breadcrumbs /> },
  { path: "docs/com/buttons", element: <Buttons /> },
  { path: "docs/com/cards", element: <Cards /> },
  { path: "docs/com/carousel", element: <Carousels /> },
  { path: "docs/com/dropdown", element: <Dropdowns /> },
  { path: "docs/com/images", element: <Images /> },
  { path: "docs/com/listgroup", element: <Listgroup /> },
  { path: "docs/com/markers", element: <Markers /> },
  { path: "docs/com/modal", element: <Modals /> },
  { path: "docs/com/navtabs", element: <NavTabs /> },
  { path: "docs/com/offcanvas", element: <OffCanvas /> },
  { path: "docs/com/pagination", element: <Paginations /> },
  { path: "docs/com/placeholders", element: <Placeholders /> },
  { path: "docs/com/popovers", element: <Popovers /> },
  { path: "docs/com/progress", element: <Progress /> },
  { path: "docs/com/spinners", element: <Spinners /> },
  { path: "docs/com/toasts", element: <Toasts /> },
  { path: "docs/com/tooltips", element: <Tooltips /> },
  { path: "docs/com/tables", element: <Tables /> },
  { path: "docs/form/elements", element: <FormElements /> },
  { path: "docs/form/selects", element: <FormSelects /> },
  { path: "docs/form/checksradios", element: <FormChecksRadios /> },
  { path: "docs/form/range", element: <FormRange /> },
  { path: "docs/form/pickers", element: <FormPickers /> },
  { path: "docs/form/layouts", element: <FormLayouts /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "docs/map/leaflet", element: <MapLeaflet /> },
  { path: "docs/map/vector", element: <MapVector /> },
  { path: "docs/icon/remix", element: <IconRemix /> },
  { path: "docs/icon/feather", element: <IconFeather /> },
  { path: "docs/util/background", element: <UtilBackground /> },
  { path: "docs/util/border", element: <UtilBorder /> },
  { path: "docs/util/colors", element: <UtilColors /> },
  { path: "docs/util/divider", element: <UtilDivider /> },
  { path: "docs/util/flex", element: <UtilFlex /> },
  { path: "docs/util/sizing", element: <UtilSizing /> },
  { path: "docs/util/spacing", element: <UtilSpacing /> },
  { path: "docs/util/opacity", element: <UtilOpacity /> },
  { path: "docs/util/position", element: <UtilPosition /> },
  { path: "docs/util/typography", element: <UtilTypography /> },
  { path: "docs/util/shadows", element: <UtilShadows /> },
  { path: "docs/util/extras", element: <UtilExtras /> }
]

export default protectedRoutes;