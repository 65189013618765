import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Card, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Grid } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";

export default function GeneralHome() {
    return (
        <React.Fragment>
            <Header />
            <div className="main p-4 mt-5 p-lg-5">
                <Row className="g-5">
                    <Col xl="12">
                        <ol className="breadcrumb fs-sm mb-2">
                            <li className="breadcrumb-item"><Link to="/">Anasayfa</Link></li>
                            <li className="breadcrumb-item">Genel Kültür </li>
                        </ol>
                        <h2 className="main-title mb-3">Genel Kültür </h2>

                        <p className="text-secondary mb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    </Col>
                    <Col xl="4">
                        <Card>
                            <Card.Header>Bilgi Bankası</Card.Header>
                            <Card.Body>
                                <Card.Text><Link to="/genel-kultur/bilgi-bankasi"><Button variant="primary" size="sm">Görüntüle</Button></Link></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card>
                            <Card.Header>Konu Bankası</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Link to="/genel-kultur/konu-bankasi"> <Button variant="primary" size="sm">Görüntüle</Button></Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card>
                            <Card.Header>Soru Bankası</Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Link to="/genel-kultur/soru-bankasi"><Button variant="primary" size="sm">Görüntüle</Button></Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}
